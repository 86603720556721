import * as React from 'react';
import { PageProps } from 'gatsby';

import Seo from '../components/seo';
import Layout from '../components/layout';

import * as styles from './shared.module.scss';
import Section from '../components/section';
import Container from '../components/container';

export const AboutPage = ({ location }: PageProps<{}>) => {
  return (
    <Layout location={location}>
      <Seo title="About" />
      <Section fixedWidth>
        <Container>
          <h2>About</h2>
          <div>
            <p>
              Damon and Derek are two brothers who grew up in Alameda, California. Damon lives in
              Northern California while Derek lives in Southern California.
            </p>
            <p>
              This blog was started so they could share with the world their trip itineraries, tips
              and tricks on how to maximize travel, and more!
            </p>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default AboutPage;
